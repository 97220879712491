.m_switch {
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: #F1F1F5;
  overflow: hidden;
  box-shadow: inset 0 0 0px 2px #F1F1F5;
  transition: 0.25s all;
  transition-delay: 0s, .08s;
  margin: 0;
  padding: 0;
  position: relative;
}

.m_switch_color {
  background-color: #9DE44F !important;
	box-shadow: inset 0 0 0px 2px #F1F1F5 !important;
}

.m_switch_b {
  display: block;
}

.m_switch_check {
  margin: 0;
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.m_switch_element {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 48%;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.m_switch_checked {
  right: 2px !important;
  left: 48% !important;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) !important;
  transition-property: left, right !important;
  transition-delay: .08s, 0s !important;
}