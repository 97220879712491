.section {
	padding-bottom: 100px;
}

.white-box {
	margin: 0 0 15px 0;
	padding: 25px 35px;
	background: $base_white_color;
	border: 1px solid #EEEEEE;
	@include border-radius(4px);
	.wb-title {
		margin: 0 0 20px 0;
		font-size: 22px;
		line-height: 30px;
		color: #5A5A5A;
		font-weight: 700;
	}
}

.back-lnk {
	padding: 0 0 0 35px;
	position: relative;
	top: 5px;
	float: right;
	font-size: 16px;
	line-height: 22px;
	color: #000;
	font-weight: 400;
	text-decoration: none;
	&:before {
		margin-top: -12px;
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 25px;
		height: 25px;
		background: url(../images/back_arr.svg) no-repeat;
		background-size: contain;
	}
}

.tags {
	font-size: 0;
	a {
		margin-right: 9px;
		margin-bottom: 9px;
		padding: 0 10px;
		display: inline-block;
		vertical-align: top;
		height: 26px;
		line-height: 26px;
		font-size: 14px;
		color: #B7B7B7;
		font-weight: 400;
		text-decoration: none;
		background: #F3F3F3;
		@include transition(all 0.3s ease 0s);
		@include border-radius(2px);
		&:hover {
			color: $base_white_color;
			background: #FECC00;
		}
	}
}

.likes {
	font-size: 0;
	.plus, .minus {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 30px;
		height: 34px;
		cursor: pointer;
		&:before, &:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			@include transition(all 0.3s ease 0s);
		}
		&:hover {
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	.plus {
		&:before {
			background: url(../images/plus.svg) no-repeat center center;
			background-size: contain;
		}
		&:after {
			background: url(../images/plus2.svg) no-repeat center center;
			background-size: contain;
			opacity: 0;
		}
	}
	.minus {
		&:before {
			background: url(../images/minus.svg) no-repeat center center;
			background-size: contain;
		}
		&:after {
			background: url(../images/minus2.svg) no-repeat center center;
			background-size: contain;
			opacity: 0;
		}
	}
	.count {
		margin: 0 15px;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 34px;
		color: #474747;
		font-weight: 700;
	}
}

.sort-box {
	a {
		margin-right: 25px;
		position: relative;
		font-size: 16px;
		line-height: 22px;
		color: #6D6D6D;
		&:before {
			margin-left: -22px;
			content: '';
			position: absolute;
			left: 50%;
			bottom: -25px;
			width: 44px;
			height: 3px;
			background: #FECC00;
			opacity: 0;
			@include transition(all 0.3s ease 0s);
		}
		&.active {
			color: #474747;
			font-weight: 700;
			&:before {
				opacity: 1;
			}
		}
	}
}

.posts-item {
	&.sticky {
		background: #F9FFF7;
	}
	&.reviews-item {
		margin: 0 0 50px 0;
		padding: 30px 0;
		border-bottom: 1px solid #E6E6E6;
		.company {
			.desc {
				margin-left: 50px;
				float: none;
			}
			.name {
				position: relative;
				top: -4px;
				margin: 0;
				float: none;
				line-height: 21px;
				font-weight: 400;
				a {
					color: #000;
					font-weight: 700;
				}
			}
			.date {
				margin-left: 8px;
				float: none;
				display: inline-block;
				line-height: 21px;
			}
		}
		.details {
			.tags {
				float: right;
				a {
					margin-bottom: 0;
					padding: 0 10px;
					line-height: 26px;
					background: #E4E4E4;
				}
			}
		}
	}
	.info {
		margin: 0 0 20px 0;
		position: relative;
	}
	.company {
		float: left;
		.img {
			float: left;
			width: 34px;
			@include border-radius(34px);
		}
		.desc {
			float: left;
		}
		.name {
			margin-left: 15px;
			margin-right: 15px;
			float: left;
			font-size: 14px;
			line-height: 34px;
			color: #000;
			font-weight: 700;
		}
		.date {
			float: left;
			font-size: 14px;
			line-height: 34px;
			color: #B7B7B7;
			font-weight: 400;
		}
	}
	.category {
		float: right;
		a, span {
			display: inline-block;
			padding: 0 20px;
			height: 34px;
			line-height: 34px;
			font-size: 14px;
			color: $base_white_color;
			font-weight: 700;
			background: #FECC00;
			text-decoration: none;
			@include border-radius(3px);
			&.blog-clr {
				background: #F4C0DD;
			}
			&.news-clr {
				background: #76DE55;
			}
			&.rev-clr {
				color: #76DE55;
				background: none;
				line-height: 32px;
				border: 1px solid #76DE55;
			}
		}
	}
	.intro {
		.title {
			margin: 0 0 15px 0;
			font-size: 22px;
			line-height: 30px;
			color: #000;
			font-weight: 700;
		}
		.text {
			p {
				margin: 15px 0;
				font-size: 16px;
				line-height: 26px;
				color: #000;
				font-weight: 400;
			}
			ul {
				margin: 15px 0;
				padding: 0;
				list-style: none;
				li {
					position: relative;
					margin: 5px 0;
					padding: 0 0 0 20px;
					font-size: 16px;
					color: $base_text_color;
					line-height: 32px;
					font-weight: 400;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 12px;
						width: 8px;
						height: 8px;
						background: $base_hover_color;
						@include border-radius(8px);
					}
				}
			}
			ol {
				margin: 15px 0;
				padding: 0;
				list-style: none;
				counter-reset: myCounter;
				li {
					position: relative;
					margin: 5px 0;
					padding: 0 0 0 20px;
					font-size: 16px;
					color: $base_text_color;
					line-height: 32px;
					font-weight: 400;
					&:before {
						content:counter(myCounter);
						counter-increment: myCounter;
						position: absolute;
						left: 0;
						top: 0;
						font-size: 16px;
						line-height: 32px;
						font-weight: 800;
						color: $base_hover_color;
					}
				}
			}
		}
		.image {
			margin: 25px 0;
			font-size: 0;
			img {
				max-width: 100%;
			}
		}
	}
	.intro-slide {
		position: relative;
		margin: 25px 0;
		height: 376px;
		.desc {
			padding: 50px 30px 0 30px;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 2;
		}
		.title {
			margin: 0 0 15px 0;
			font-size: 30px;
			line-height: 40px;
			color: $base_white_color;
			font-weight: 700;
			display: block;
		}
		.text {
			margin: 0 0 30px 0;
			font-size: 16px;
			line-height: 26px;
			color: $base_white_color;
			display: block;
		}
		.more {
			padding: 0 44px;
			display: inline-block;
			vertical-align: top;
			height: 44px;
			line-height: 40px;
			font-size: 16px;
			font-weight: 700;
			color: $base_white_color;
			border: 2px solid #FFFFFF;
			@include border-radius(3px);
		}
		.slide {
			position: absolute;
			overflow: hidden;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			@include border-radius(4px);
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5);
			}
		}
	}
	.details {
		.tags {
			margin-top: 0;
			padding: 0;
			margin-left: 40px;
			float: left;
			border: none;
			a {
				padding: 0;
				background: none;
				line-height: 34px;
				&:hover {
					color: $base_hover_color;
				}
			}
		}
		.likes {
			float: left;
		}
	}
	.author {
		float: right;
		font-size: 0;
		.name {
			margin-right: 15px;
			display: inline-block;
			vertical-align: middle;
			font-size: 14px;
			line-height: 34px;
			font-weight: 700;
			color: #B7B7B7;
		}
		.avatar {
			display: inline-block;
			vertical-align: middle;
			width: 34px;
			height: auto;
			@include border-radius(34px);
		}
	}
	.tags {
		margin-top: 15px;
		padding: 15px 0 0 0;
		border-top: 1px solid #F3F3F3;
	}
}

.category-box {
	.category-bts {
		a {
			margin: 0 0 10px 0;
			padding: 0 30px;
			display: block;
			height: 50px;
			line-height: 46px;
			font-size: 16px;
			color: #575757;
			border: 2px solid #EEEEEE;
			text-decoration: none;
			@include transition(all 0.3s ease 0s);
			@include border-radius(3px);
			&:hover, &.active {
				background: #FECC00;
				border-color: #FECC00;
				color: $base_white_color;
			}
		}
	}
}

.discuss-box {
	.more {
		padding-right: 25px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 26px;
		color: #3A3A3A;
		font-weight: 700;
		&:before {
			margin-top: -7px;
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 14px;
			height: 14px;
			background: url(../images/arr.svg) no-repeat;
			background-size: contain;
			@include transition(all 0.3s ease 0s);
		}
		&.active {
			&:before {
				@include transform(rotate(90deg));
			}
		}
	}
}

.discuss-items {
	margin: 0 0 28px 0;
}

.discuss-item {
	margin: 0 0 14px 0;
	a {
		font-size: 16px;
		line-height: 24px;
		color: #575757;
		font-weight: 600;
		text-decoration: none;
		@include transition(all 0.3s ease 0s);
		&:hover {
			span {
				border-color: #FECC00;
			}
		}
	}
	img {
		float: left;
		margin-right: 10px;
		width: 24px;
		display: inline-block;
		vertical-align: middle;
		@include border-radius(24px);
	}
	span {
		border-bottom: 2px solid transparent;
		@include transition(all 0.3s ease 0s);
	}
}