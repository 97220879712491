@media screen and (max-width: 1240px) {
	.fw {width: 724px;}
	
	/*header*/
	.header .top-menu {display: none;}
	.header .menu-btn {display: block;}

	/*home*/
	.left-column {float: none; width: auto;}
	.right-column {float: none; width: auto;}
}

@media screen and (max-width: 730px) {
	.fw {width: 300px;}
	.wrapper {padding-top: 100px;}
	a.up-btn {padding: 0; font-size: 0; width: 60px; height: 60px;}
	a.up-btn:before {margin-left: -8px; margin-top: -8px; left: 50%; top: 50%;}
	.c-form .field input[type="text"], .c-form .field input[type="tel"], .c-form .field input[type="password"], .c-form .field input[type="email"], .c-form .field input[type="number"], .c-form .field textarea {padding: 0 15px; height: 40px; font-size: 14px;}
	.c-form .group.submit {margin: 10px 0;}
	a.btn, .btn {padding: 0; display: block; height: 40px; line-height: 38px; font-size: 14px;}
	
	/*header*/
	.header .slogan {display: none;}
	.header .search {display: none;}
	.header .login {margin: 0; display: none; position: absolute; top: 90px; float: none;}
	.header .login a {font-size: 14px; line-height: 30px;}
	.header .social {text-align: center;}
	.header .menu-btn {margin-top: 20px; margin-left: 20px; width: 100px; height: 40px; line-height: 38px; font-size: 14px;}
	.header .social {margin-top: 30px;}
	.header .social a {margin: 0 0 0 8px; width: 22px; height: 22px;}

	/*home*/
	.white-box, .right-column .white-box {padding: 20px;}
	.sort-box a {font-size: 14px;}
	.sort-box a:before {bottom: -22px;}
	.posts-item .company {float: none;}
	.posts-item .company .desc {margin-left: 45px; float: none; display: block;}
	.posts-item .company .name {margin-left: 0; margin-right: 0; display: block; float: none; font-size: 14px; line-height: 18px;}
	.posts-item .company .date {float: none; display: block; font-size: 12px; line-height: 18px;}
	.posts-item .category {float: none; margin-top: 0; position: absolute; right: 0; top: 0;}
	.posts-item .category a, .posts-item .category span {padding: 0 8px; height: 26px; line-height: 26px; font-size: 10px;}
	.posts-item .category a.rev-clr, .posts-item .category span.rev-clr {line-height: 24px;}
	.posts-item .intro .title {margin: 0 0 10px 0; font-size: 18px; line-height: 28px;}
	.posts-item .intro .text p {margin: 10px 0; font-size: 14px; line-height: 22px;}
	.posts-item .intro .text ul li, .posts-item .intro .text ol li {font-size: 14px; line-height: 22px;}
	.posts-item .intro .text ul li:before {top: 8px;}
	.posts-item .intro .text ol li:before {font-size: 14px; line-height: 22px;}
	.posts-item .intro .image {margin: 15px 0; font-size: 0;}
	.likes .count {margin: 0 8px; font-size: 14px; line-height: 26px;}
	.likes .plus, .likes .minus {width: 24px; height: 26px;}
	.posts-item .author .name {margin-right: 8px; font-size: 11px; line-height: 26px;}
	.posts-item .author .avatar {width: 26px;}
	.tags a {margin-right: 5px; margin-bottom: 5px; padding: 0 8px; font-size: 11px;}
	.posts-item .intro-slide .desc {padding: 20px 15px 0 15px;}
	.posts-item .intro-slide .title {margin: 0 0 10px 0; font-size: 18px; line-height: 28px;}
	.posts-item .intro-slide .text {margin: 0 0 15px 0; font-size: 14px; line-height: 22px;}
	.posts-item .intro-slide .more {padding: 0 30px; height: 36px; line-height: 32px; font-size: 14px;}
	.posts-item .details .tags {margin-left: 0; margin-top: 10px; width: 100%;}
	.posts-item .details .tags a {line-height: normal; height: auto;}
	.white-box .wb-title {margin: 0 0 15px 0; font-size: 18px; line-height: 28px;}
	.category-box .category-bts a {padding: 0 20px; height: 40px; line-height: 36px; font-size: 14px;}
	.discuss-item a {font-size: 14px; line-height: 24px;}

	/*inner*/
	.text-box h1, .text-box h2, .text-box h3, .text-box h4, .text-box h5, .text-box h6 {font-size: 20px; line-height: 30px;}
	.text-box h2, .text-box h3, .text-box h4, .text-box h5, .text-box h6 {font-size: 18px; line-height: 28px;}
	.text-box p {font-size: 14px; line-height: 22px;}
	.text-box p br {display: none;}
	.text-box blockquote {padding: 50px 0 10px 10px; font-size: 14px; line-height: 22px; border-left-width: 2px;}
	.gallery-carousel {margin: 20px 0;}
	.text-box ul, .text-box ol {margin: 15px 0;}
	.text-box ul li, .text-box ol li {margin: 15px 0; font-size: 14px; line-height: 22px;}
	.text-box ul li:before {top: 8px;}
	.text-box ol li:before {font-size: 14px; line-height: 22px;}
	.text-box blockquote {padding: 35px 0 0 10px;}
	.text-box blockquote:before {left: 10px; top: 0;}
	.text-box blockquote.b-type-2 {padding: 45px 15px 15px 15px;}
	.text-box blockquote.b-type-2:before {top: 15px; left: 15px;}
	.view-box .view-btn {font-size: 14px;}
	.view-box .view-btn:after {margin-left: 5px; width: 16px; height: 16px;}
	.comments-item .text {font-size: 14px; line-height: 22px;}
	.comments-item .answer-lnk a {font-size: 14px; line-height: 22px;}
	.comments-item .info .desc {margin-left: 45px; display: block;}
	.comments-item .info .name {margin-left: 0; margin-right: 0; display: block; float: none; font-size: 14px; line-height: 18px;}
	.comments-item .info .date {float: none; display: block; font-size: 12px; line-height: 18px;}
	.comments-item.answer {margin: 0 0 0 15px;}
	.comments-box .view-all {font-size: 12px;}
	.comments-form .desc .field {padding-right: 0; width: 100%;}
	.comments-form .desc .submit-btn {margin-top: 10px; float: left; width: 100%;}
	.login-comm-box {padding: 15px; font-size: 12px;}
	.profile-box .i-rev-btn {font-size: 12px;}
	.profile-box .profile-btn {margin-top: 15px; padding: 0 35px 0 15px; position: relative;}
	.c-form .submit-btn {float: none; width: 100%;}
	.c-form a.recovery-lnk {float: none; display: block; text-align: center;}
	.form-box .reg-bts {font-size: 14px;}
	.form-box .soc-bts {padding-top: 15px; margin-top: 20px;}
	.form-box .soc-bts .label {margin: 0 0 10px 0; font-size: 14px;}
	.form-box .soc-bts a {width: 44px; height: 44px;}

	/*restoran*/
	.restoran-item .company .img {width: 50px;}
	.restoran-item .company .img:before {width: 15px; height: 15px;}
	.restoran-item .company .desc {margin-left: 65px;}
	.restoran-item .company .name {padding: 5px 0 0 0; font-size: 12px; line-height: 20px;}
	.restoran-item .company .name strong {font-size: 18px; line-height: 22px;}
	.restoran-item .info .rev-btn {margin-top: 10px; float: none; display: inline-block; font-size: 14px; line-height: 22px;}
	.restoran-item .intro .text p {font-size: 14px; line-height: 22px;}
	.restoran-item .chars {margin: 0 0 20px 0;}
	.restoran-item .chars ul li {margin: 0 0 10px 0; width: 100%; font-size: 14px;}
	.restoran-item .soc-lnks .soc-lnk {width: 50%; font-size: 14px;}
	.posts-item.reviews-item .info {padding-top: 45px;}
	.posts-item.reviews-item .category {right: auto; left: 0;}

	/*nonebox*/
	.nonebox {width: 300px; margin: 0 0 0 -150px; padding: 20px;}
	.nonebox .title {font-size: 22px; line-height: 28px;}
	.nonebox .subtitle {font-size: 14px; line-height: 22px;}
	.nonebox.revs textarea {padding: 20px; font-size: 14px;}
	.nonebox.revs .submit-btn {float: none; width: 100%;}
	.jq-selectbox__select {padding: 0 45px 0 20px; font-size: 14px;}
}