* {
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

::-webkit-input-placeholder {color: $base_form_placeholder;}
::-moz-placeholder		{color: $base_form_placeholder;}
:-moz-placeholder		{color: $base_form_placeholder;}
:-ms-input-placeholder	{color: $base_form_placeholder;}

html, body {
	margin: 0;
	padding: 0;
	background: $base_background url(../images/bg.png);
	font-size: $base_font_size;
	color: $base_text_color;
	font-family: $base_font_family, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

a {
	color: $base_link_color;
	text-decoration: none;
	cursor: pointer;
	&:link {
		color: $base_link_color;
	}
	&:active {
		color: $base_link_color;
	}
	&:visited {
		color: $base_link_color;
	}
	&:hover {
		color: $base_link_color;
		text-decoration: underline; 
	}
	img {
		border: none;
	}
}

input, textarea, button {
	font-family: $base_font_family;
	-webkit-appearance: none;
	box-shadow: none !important;
}

label {
	cursor: pointer;
}

.clear {
	clear: both;
}

strong {
	font-weight: 700;
}

.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
	&.full-width {
		width: 100% !important;
	}
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

.mobile-show {display: none;}
.mobile-hide {display: block;}

input[type="number"] {
	-moz-appearance: textfield;
}

/*Buttons*/

.bts {
	text-align: center;
}

a.btn, .btn {
	padding: 0 45px;
	display: inline-block;
	vertical-align: top;
	height: 50px;
	line-height: 48px;
	font-size: 16px;
	color: #fff;
	font-weight: 600;
	text-decoration: none;
	background: $base_hover_color;
	border: 1px solid $base_hover_color;
	@include border-radius(3px);
}

/*Wrapper*/

.bg {
	position: relative;
	overflow: hidden;
}

.wrapper {
	padding: 110px 0 0 0;
	position: relative;
	z-index: 1;
}

.fw {
	position: relative;
	width: $base_width;
	margin: 0 auto;
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

.left-column {
	float: left;
	width: 754px;
}

.right-column {
	float: right;
	width: 362px;
	.white-box {
		padding: 30px 25px;
	}
}

.disabled {display: none;}

a.up-btn {
	padding-top: 45px;
	position: fixed;
	right: 15px;
	bottom: 15px;
	width: 95px;
	height: 95px;
	font-size: 14px;
	line-height: 20px;
	color: #EEEEEE;
	background: #252525;
	text-align: center;
	z-index: 10;
	@include border-radius(95px);
	&:before {
		margin-left: -8px;
		content: '';
		position: absolute;
		left: 50%;
		top: 28px;
		width: 17px;
		height: 17px;
		background: url(../images/arr_up.svg) no-repeat;
		background-size: contain;
	}
}

/*Text*/

.text-box {
	.table {
		position: relative;
		margin-bottom: 25px;
		overflow: auto;
	}
	h1, h2, h3, h4, h5, h6 {
		position: relative;
		margin: 0 0 20px 0;
		padding: 0;
		font-size: 35px;
		line-height: 48px;
		font-weight: 700;
		color: $base_text_color;
	}
	h2, h3, h4, h5, h6 {
		font-size: 22px;
		line-height: 36px;
	}
	p {
		margin: 15px 0;
		font-size: 18px;
		line-height: 32px;
		color: $base_text_color;
		font-weight: 400;
		strong {
			font-weight: 700;
		}
		img {
			max-width: 100%;
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	ul {
		margin: 20px 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			margin: 20px 0 20px 25px;
			padding: 0 0 0 20px;
			font-size: 18px;
			color: $base_text_color;
			line-height: 32px;
			font-weight: 600;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 12px;
				width: 8px;
				height: 8px;
				background: $base_hover_color;
				@include border-radius(8px);
			}
		}
	}
	ol {
		margin: 20px 0;
		padding: 0;
		list-style: none;
		counter-reset: myCounter;
		li {
			position: relative;
			margin: 20px 0 20px 25px;
			padding: 0 0 0 20px;
			font-size: 18px;
			color: $base_text_color;
			line-height: 32px;
			font-weight: 400;
			&:before {
				content:counter(myCounter);
				counter-increment: myCounter;
				position: absolute;
				left: 0;
				top: 0;
				font-size: 18px;
				line-height: 32px;
				font-weight: 800;
				color: $base_hover_color;
			}
		}
	}
	table {
		margin: 0;
		padding: 0;
		width: 682px;
		border-spacing: 0;
		border-collapse: collapse;
		tr {
			&:first-child {
				td {
					padding-top: 0;
				}
			}
		}
		td {
			padding: 16px 0 8px 0;
			color: $base_text_color;
			font-family: $base_font_family;
			font-size: 16px;
			line-height: 22px;
			font-weight: 400;
			text-align: left;
			border-bottom: 1px solid #EDEDED;
			a {
				color: $base_link_color;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	blockquote {
		position: relative;
		margin: 20px 0;
		padding: 50px 20px 10px 20px;
		background: #fff;
		font-size: 18px;
		line-height: 32px;
		color: $base_text_color;
		font-style: italic;
		font-weight: 400;
		border-left: 4px solid #FECC00;
		&.b-type-2 {
			padding: 40px 20px 40px 65px;
			background: #FFFBED;
			border-color: #FFFBED;
			&:before {
				top: 45px;
				left: 15px;
				background: url(../images/blockquote2.svg) no-repeat;
				background-size: contain;
			}
		}
		&:before {
			content: '';
			position: absolute;
			left: 20px;
			top: 12px;
			width: 32px;
			height: 32px;
			background: url(../images/blockquote.svg) no-repeat;
			background-size: contain;
		}
		a {
			color: $base_hover_color;
			font-style: italic;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.gallery-carousel {
	margin: 50px 0;
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
	.item {
		font-size: 0;
		img {
			max-width: 100%;
			@include border-radius(4px);
		}
	}
	.owl-prev, .owl-next {
		margin-top: -20px;
		position: absolute;
		left: 25px;
		top: 50%;
		width: 40px;
		height: 40px;
		background: url(../images/nav.svg) no-repeat;
		background-size: contain;
		cursor: pointer;
		font-size: 0;
		opacity: 0.7;
		@include transition(all 0.3s ease 0s);
		@include border-radius(40px);
		&:hover {
			opacity: 1;
		}
	}
	.owl-next {
		left: auto;
		right: 25px;
		@include transform(scaleX(-1));
	}
}

/*Preloader*/

.preloader {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: $base_white_color;
	z-index: 1000;
	.spinner {
		width: 64px;
		height: 64px;
		display: inline-block;
		position: relative;
		background: url(../images/preloader.gif) no-repeat;
		background-size: 100% 100%;
	}
}

/*Forms*/

.c-form {
	.group {
		position: relative;
		margin: 0 0 10px 0;
		&.submit {
			margin: 20px 0;
		}
	}
	.field {
		position: relative;
		input[type="text"], input[type="tel"], input[type="password"], input[type="email"], input[type="number"], textarea {
			padding: 0 20px;
			width: 100%;
			height: 50px;
			font-size: 16px;
			color: $base_text_color;
			font-family: $base_font_family;
			font-weight: 400;
			background: none;
			border: 2px solid #EEEEEE;
			@include border-radius(3px);
			&.error {
				border-color: red!important;
			}
			&:focus {
				color: $base_hover_color;
				border-left-color: $base_hover_color;
			}
		}
		textarea {
			padding: 15px 20px;
			height: 100px;
			resize: none;
			&.error {
				border-color: red!important;
			}
		}
	}
	label.error {
		position: absolute;
		left: 0;
		bottom: -15px;
		font-size: 11px;
		letter-spacing: -0.05em;
		color: red;
		display: none!important;
	}
	.submit-btn {
		float: left;
		border: none;
		outline: none;
		cursor: pointer;
		&.full {
			width: 100%;
			float: none;
		}
	}
	a.recovery-lnk {
		float: right;
		font-size: 14px;
		line-height: 50px;
		font-style: italic;
		color: #575757;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.check {
	margin: 15px 0 0 0;
	padding: 0;
	position: relative;
	width: 100%;
	display: block;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	color: #575757;
	font-style: italic;
}

/*popups*/

.overlay {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.8);
	z-index: 101;
}

.nonebox {
	position: absolute;
	left: 50%;
	top: -10000px;
	width: 558px;
	margin: 0 0 0 -279px;
	padding: 50px 40px;
	z-index: 1002;
	background: #fff;
	@include border-radius(4px);
	&.revs {
		.group {
			margin: 0 0 15px 0;
		}
		textarea {
			padding: 20px 30px;
			width: 100%;
			height: 240px;
			font-size: 16px;
			line-height: 22px;
			color: $base_text_color;
			font-style: italic;
			border: 1px solid #E3E3E3;
			resize: none;
			@include border-radius(4px);
			&.error {
				border-color: red;
			}
		}
		.jq-selectbox label.error {
			display: none!important;
		}
		label.error {
			font-size: 11px;
			color: red;
		}
		.submit-btn {
			float: right;
			cursor: pointer;
			outline: none;
		}
	}
	.close {
		position: absolute;
		top: -50px;
		right: 0;
		width: 30px;
		height: 30px;
		cursor: pointer;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/close.svg) no-repeat;
			background-size: contain;
		}
	}
	.title {
		margin: 0 0 15px 0;
		font-size: 28px;
		line-height: 38px;
		color: #000;
		font-weight: 700;
	}
	.subtitle {
		margin: 0 0 25px 0;
		font-size: 16px;
		line-height: 28px;
		color: #000;
		font-weight: 400;
	}
}