$base_background: #F9F9F9;
$base_text_color: #000;
$base_white_color: #fff;
$base_link_color: #FECC00;
$base_red_color: red;
$base_hover_color: #FECC00;
$base_orange_color: #FECC00;
$base_font_size: 16px;
$base_font_family: 'Open Sans';
$base_font2_family: 'Open Sans';
$base_width: 1146px;
$base_form_placeholder: #B7B7B7;