.profile-box {
	.image {
		float: left;
		width: 50px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.desc {
		position: relative;
		margin-left: 70px;
	}
	.name {
		margin: 0 0 5px 0;
		display: block;
		font-size: 16px;
		line-height: 22px;
		color: #000;
		font-weight: 700;
	}
	.exit {
		margin: 0 0 5px 0;
		display: block;
		font-size: 14px;
		line-height: 20px;
		color: #B7B7B7;
	}
	.i-rev-btn {
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		line-height: 20px;
		color: #454545;
		text-decoration: none;
		border-bottom: 2px dashed #FECC00;
		@include transition(all 0.3s ease 0s);
		&:hover {
			border-color: transparent;
		}
	}
	.profile-btn {
		padding: 0 35px 0 15px;
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		color: #B7B7B7;
		height: 36px;
		line-height: 34px;
		text-decoration: none;
		border: 1px solid #EEEEEE;
		@include border-radius(2px);
		&:before {
			margin-top: -8px;
			content: '';
			position: absolute;
			top: 50%;
			right: 15px;
			width: 16px;
			height: 16px;
			background: url(../images/set.svg) no-repeat;
			background-size: contain;
		}
	}
}

.form-box {
	.reg-bts {
		font-size: 16px;
		line-height: 22px;
		color: #575757;
		a {
			font-weight: 700;
			color: $base_hover_color;
		}
	}
	.soc-bts {
		margin-top: 30px;
		padding-top: 25px;
		border-top: 1px solid #EEEEEE;
		font-size: 0;
		.label {
			margin: 0 0 15px 0;
			font-size: 16px;
			line-height: 22px;
			color: #B7B7B7;
		}
		a {
			margin-right: 9px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 70px;
			height: 50px;
			border: 1px solid #E2E2E2;
			@include border-radius(50px);
			&.vk {
				&:before {
					background: url(../images/c_vk.svg) no-repeat;
					background-size: contain;
				}
			}
			&.fb {
				&:before {
					background: url(../images/c_fb.svg) no-repeat;
					background-size: contain;
				}
			}
			&.gp {
				&:before {
					background: url(../images/c_gp.svg) no-repeat;
					background-size: contain;
				}
			}
			&.in {
				&:before {
					background: url(../images/c_in.svg) no-repeat;
					background-size: contain;
				}
			}
			&:before {
				margin: -11px 0 0 -11px;
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 22px;
				height: 22px;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.view-box {
	margin: -20px 0 50px 0;
	.view-btn {
		padding: 17px 0;
		display: block;
		font-size: 16px;
		line-height: 22px;
		color: #E8E8E8;
		font-weight: 400;
		text-align: center;
		background: #252525;
		@include border-radius(2px);
		&:after {
			margin-left: 15px;
			content: '';
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 26px;
			height: 26px;
			background: url(../images/arr2.svg) no-repeat;
			background-size: contain;
		}
	}
}

.comments-box {
	.view-all {
		display: block;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		color: #888888;
		font-weight: 400;
		background: #EDEDED;
		text-align: center;
		@include border-radius(2px);
	}
}

.comments-items {
	margin: 25px 0 0 0;
}

.comments-item {
	margin: 0 0 25px 0;
	padding: 0 0 10px 0;
	border-bottom: 1px solid #EDEDED;
	&.answer {
		margin: 0 0 0 75px;
		padding: 10px 0 0 0;
		border: none;
	}
	.info {
		margin: 0 0 12px 0;
		.img {
			float: left;
			width: 34px;
			@include border-radius(34px);
		}
		.name {
			margin-left: 15px;
			margin-right: 15px;
			float: left;
			font-size: 14px;
			line-height: 34px;
			color: #000;
			font-weight: 700;
		}
		.date {
			float: left;
			font-size: 14px;
			line-height: 34px;
			color: #B7B7B7;
			font-weight: 400;
		}
	}
	.text {
		margin: 0 0 8px 0;
		font-size: 16px;
		line-height: 26px;
		color: #000;
	}
	.answer-lnk {
		margin: 0 0 8px 0;
		a {
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			line-height: 26px;
			color: $base_hover_color;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.all-comm {
		padding: 0 0 0 27px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		&:before {
			margin-top: -8px;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 17px;
			height: 17px;
			background: url(../images/c_arr.svg) no-repeat;
			background-size: contain;
		}
		span {
			border-bottom: 1px solid $base_hover_color;
		}
		&:hover {
			span {
				border-bottom-color: transparent;
			}
		}
	}
}

.comments-form {
	margin: 20px 0 0 0;
	.image {
		float: left;
		width: 34px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.desc {
		position: relative;
		margin-left: 50px;
		font-size: 0;
		&:after {
			content: '';
			position: relative;
			clear: both;
			display: block;
		}
		.field {
			padding-right: 15px;
			float: left;
			width: 80%;
			input {
				padding: 0 20px;
				width: 100%;
				height: 40px;
				font-family: $base_font_family;
				font-size: 14px;
				color: #888;
				font-weight: 400;
				background: $base_white_color;
				border: 1px solid #EDEDED;
				@include border-radius(4px);
				&.error {
					border-color: red!important;
				}
			}
		}
		.submit-btn {
			float: right;
			width: 20%;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			color: $base_white_color;
			font-weight: 400;
			background: $base_hover_color;
			border: none;
			outline: none;
			cursor: pointer;
			@include border-radius(4px);
		}
	}
}

.login-comm-box {
	margin: -20px 0 20px 0;
	padding: 15px 15px 15px 60px;
	font-size: 14px;
	line-height: 20px;
	color: $base_white_color;
	background: #3A3A3A;
	@include border-radius(2px);
	a {
		color: $base_hover_color;
		font-weight: 700;
	}
}

.restoran-item {
	.info {
		margin: 0 0 20px 0;
		.rev-btn {
			margin-top: 20px;
			float: right;
			font-size: 16px;
			line-height: 22px;
			color: #000;
			text-decoration: none;
			font-weight: 600;
			border-bottom: 2px dashed #FECC00;
			@include transition(all 0.3s ease 0s);
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
	.company {
		float: left;
		.img {
			position: relative;
			float: left;
			width: 90px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				width: 30px;
				height: 30px;
				background: url(../images/check.svg) no-repeat;
				background-size: contain;
			}
			img {
				width: 100%;
				@include border-radius(34px);
			}
		}
		.desc {
			margin-left: 110px;
		}
		.name {
			padding: 20px 0 0 0;
			font-size: 16px;
			line-height: 22px;
			color: #B8B8B8;
			font-weight: 600;
			strong {
				display: block;
				font-size: 25px;
				line-height: 34px;
				color: #000;
			}
		}
	}
	.intro {
		.text {
			p {
				margin: 15px 0;
				font-size: 16px;
				line-height: 26px;
				color: #000;
				font-weight: 400;
			}
			ul {
				margin: 15px 0;
				padding: 0;
				list-style: none;
				li {
					position: relative;
					margin: 5px 0;
					padding: 0 0 0 20px;
					font-size: 16px;
					color: $base_text_color;
					line-height: 32px;
					font-weight: 400;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 12px;
						width: 8px;
						height: 8px;
						background: $base_hover_color;
						@include border-radius(8px);
					}
				}
			}
			ol {
				margin: 15px 0;
				padding: 0;
				list-style: none;
				counter-reset: myCounter;
				li {
					position: relative;
					margin: 5px 0;
					padding: 0 0 0 20px;
					font-size: 16px;
					color: $base_text_color;
					line-height: 32px;
					font-weight: 400;
					&:before {
						content:counter(myCounter);
						counter-increment: myCounter;
						position: absolute;
						left: 0;
						top: 0;
						font-size: 16px;
						line-height: 32px;
						font-weight: 800;
						color: $base_hover_color;
					}
				}
			}
		}
	}
	.chars {
		margin: 0 0 30px 0;
		ul {
			margin: 0;
			padding: 10px 0;
			list-style: none;
			font-size: 0;
			border-bottom: 1px solid #F3F3F3;
			li {
				margin: 0 0 20px 0;
				display: inline-block;
				vertical-align: top;
				width: 49%;
				font-size: 16px;
				line-height: 22px;
				color: #000;
				font-weight: 600;
				span {
					display: block;
					font-size: 14px;
					line-height: 20px;
					color: #B7B7B7;
					font-weight: 400;
				}
				a {
					font-size: 16px;
					line-height: 22px;
					color: #000;
					font-weight: 600;
				}
			}
		}
	}
	.soc-lnks {
		font-size: 0;
		.soc-lnk {
			margin-bottom: 10px;
			padding: 0 0 0 30px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 25%;
			font-size: 16px;
			line-height: 22px;
			color: #000;
			text-decoration: none;
			&.disable {
				&:before {
					filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
					filter: gray; /* IE6-9 */
					-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
				}
				span {
					color: #B7B7B7;
					border: none;
					&:after {
						display: none;
					}
				}
			}
			&.url {
				&:before {
					background: url(../images/r_url.svg) no-repeat;
					background-size: contain;
				}
			}
			&.vk {
				&:before {
					background: url(../images/r_vk.svg) no-repeat;
					background-size: contain;
				}
			}
			&.in {
				&:before {
					background: url(../images/r_in.svg) no-repeat;
					background-size: contain;
				}
			}
			&.fb {
				&:before {
					background: url(../images/r_fb.svg) no-repeat;
					background-size: contain;
				}
			}
			&:before {
				margin-top: -11px;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 22px;
				height: 22px;
			}
			span {
				position: relative;
				border-bottom: 1px solid #FECC00;
				@include transition(all 0.3s ease 0s);
				&:after {
					content: '';
					position: absolute;
					top: 4px;
					right: -10px;
					width: 6px;
					height: 6px;
					background: url(../images/r_arr.svg) no-repeat;
					background-size: contain;
				}
			}
			&:hover {
				span {
					border-bottom-color: transparent;
				}
			}
		}
	}
	.gallery-carousel {
		margin-top: 20px;
		margin-bottom: 10px;
	}
}