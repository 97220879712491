/*Header*/

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 10;
	background: #252525;
	@include transition(all 0.3s ease 0s);
	&.opened {
		height: 100%;
		.login {
			display: block;
		}
		.top-menu {
			margin: 80px 0 0 0;
			width: 100%;
			display: block;
			ul {
				li {
					margin: 0 0 15px 0;
					display: block;
					a {
						padding: 0;
						display: inline-block;
						height: auto;
						line-height: normal;
						border-top: none;
						border-bottom: 2px solid transparent;
					}
					&.active {
						a {
							border-bottom-color: $base_hover_color;
						}
					}
				}
			}
		}
	}
	.logo {
		margin-top: 14px;
		float: left;
		width: 65px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.top-menu {
		margin: 0 0 0 80px;
		padding: 0;
		float: left;
		ul {
			font-size: 0;
			li {
				margin: 0 25px 0 0;
				display: inline-block;
				vertical-align: top;
				a {
					padding: 0 15px;
					display: inline-block;
					height: 80px;
					font-size: 16px;
					line-height: 76px;
					color: #fff;
					font-weight: 400;
					text-decoration: none;
					border-top: 4px solid transparent;
					@include transition(all 0.3s ease 0s);
				}
				&:hover, &.active {
					a {
						color: $base_hover_color;
						border-top-color: $base_hover_color;
					}
				}
			}
		}
	}
	.login {
        margin: 0 0 0 40px;
		float: right;
		a {
			margin: 0 0 0 30px;
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
            line-height: 80px;
			color: #fff;
			font-weight: 700;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
            &:first-child {
                margin-left: 0;
            }
		}
	}
	.social {
        margin-top: 22px;
		float: right;
		font-size: 0;
		a {
			position: relative;
			margin: 0 0 0 15px;
			display: inline-block;
			vertical-align: top;
			width: 34px;
			height: 34px;
			@include border-radius(34px);
			&.vk {
				&:before {
					background: url(../images/vk.svg) no-repeat center center;
					background-size: contain;
				}
                &:before {
                    background: url(../images/vk2.svg) no-repeat center center;
                    background-size: contain;
                }
			}
			&.fb {
				&:before {
					background: url(../images/fb.svg) no-repeat center center;
					background-size: contain;
				}
                &:after {
                    background: url(../images/fb2.svg) no-repeat center center;
                    background-size: contain;
                }
			}
			&.in {
				&:before {
					background: url(../images/in.svg) no-repeat center center;
					background-size: contain;
				}
                &:after {
                    background: url(../images/in2.svg) no-repeat center center;
                    background-size: contain;
                }
			}
			&:before, &:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 1;
				@include transition(all 0.3s ease 0s);
			}
			&:after {
				opacity: 0;
			}
			&:hover {
				&:before {
					opacity: 0;
				}
				&:after {
					opacity: 1;
				}
			}
		}
	}
	.menu-btn {
		margin-top: 15px;
		margin-left: 40px;
		float: right;
		display: none;
		width: 124px;
		height: 49px;
		line-height: 47px;
		font-size: 16px;
		color: #fecc00;
		text-decoration: none;
		text-align: center;
		border: 1px solid #fecc00;
		@include border-radius(49px);
	}
}